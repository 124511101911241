import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import ImageUpload from "../../../ImageUpload";

export default function FormComponent(props) {
    const { FetchData } = props;
    const history = useHistory()
    const [ID, setID] = React.useState(FetchData.id)

    const [Title, setTitle] = React.useState(FetchData.title.rendered)
    const [PackageName, setPackageName] = React.useState(FetchData.meta.package_name)
    const [PackagePrice, setPackagePrice] = React.useState(FetchData.meta.package_price)
    const [PackageExam, setPackageExam] = React.useState(FetchData.meta.package_exam)
    const [PackageImage, setPackageImage] = React.useState(FetchData.meta.package_image)

    const [reload, setReload] = React.useState(false)

    const [AlertShow, setAlertShow] = React.useState(false)
    const [Alertcomp, setAlertcomp] = React.useState("")

    const EditInfo = () => {
        setReload(true)
        const data = {
            "title": Title,
            "status": "publish",
            "meta": {
                "package_name": Title,
                "package_price": PackagePrice,
                "package_exam":PackageExam,
                "package_image": PackageImage
            }
        }

        if (ID === "")
            AdsPOSTApi(data, slugs.db_slug_new_package)
                .then((res) => {
                    setReload(false)
                    setID(res.data.id)
                    setAlertShow(true)
                    setAlertcomp("Package Added Successfully")
                    history.push(`${slugs.new_package_details}${res.data.slug}`)
                }).catch(err => {
                    setReload(false)
                    console.log(err)
                    setAlertShow(true)
                    setAlertcomp(err.response.data.message)
                })
        else
            AdsPUTApi(data, slugs.db_slug_new_package, ID)
                .then((res) => {
                    setReload(false)
                    setAlertShow(true)
                    setAlertcomp(`${Title} edited Successfully`)
                }).catch(err => {
                    console.log(err)
                    console.warn(err.response.data)
                    console.log("Response Status: hello11", err.response.status);
                    console.log("Response Headers:", err.response.headers);
                    setReload(false)
                    setAlertShow(true)
                    setAlertcomp(err.response.data.message)
                })
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm-2 col-1"></div>
                <div className="col-sm-8 col-10 form-view mb-3">
                    <h4><center>Package Information</center></h4>
                    <table>
                        <tbody>
                            <tr>
                                <td className="col-3">Package Name</td>
                                <td className="col-6">
                                    <input className="input-common"
                                        value={Title} placeholder="Package Name"
                                        onChange={(event) => { setTitle(event.target.value) }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-3">Package Price</td>
                                <td className="col-6">
                                    <input className="input-common"
                                        value={PackagePrice} placeholder="Package Price"
                                        onChange={(event) => { setPackagePrice(event.target.value) }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-3">Number of Exam Allocated</td>
                                <td className="col-6">
                                    <input className="input-common"
                                        value={PackageExam} placeholder="Number of Exam Allocated"
                                        onChange={(event) => { setPackageExam(event.target.value) }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-3">Package Image</td>
                                <td className="col-6">
                                    <ImageUpload
                                        ImageUrl={PackageImage}
                                        setImageUrl={setPackageImage}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row center m-2">
                        <div className=" col-3" style={{ padding: "0 10px" }}>
                            <div className="sign-in-button-4"
                                onClick={() => {
                                    EditInfo()
                                }}
                            >
                                Update Package
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {reload &&
                <div className="modal" style={{ padding: "50vw" }}>
                    <div className="dot-pulse"></div>
                </div>
            }
            {
                AlertShow &&
                <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
            }
        </div>
    )

}